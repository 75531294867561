.App {

}

.top-panel {
  width: 90%;
  margin: 40px auto;
}

.header {
  display: flex;
  margin-bottom: 70px;
}

.logo {
  margin-right: 31px;
}

.header-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 45px;
  font-weight: 700;
  color: #000000;
  margin-top: 10px;
}

.special {
  font-weight: 700;
  font-size: 32px;
  color: #C10130;
}

.date {
  margin-left: auto;
  margin-top: -20px;
}

.szb-iframe {
  width: 1240px;
  height: 548px;
  margin: 36px 0 50px 0;
}

.footer {
  width: 100%;
  margin-bottom: -5px;
}

.mobile-footer {
  display: none;
}

.mobile-header {
  display: none;
}

video {
  margin: 0 auto;
  display: block;
}

@media (max-width: 991px){

  .top-panel {
    margin: 20px auto;
  }
  .header {
    display: none;
  }

  .mobile-header {
    display: flex;
    margin-bottom: 20px;
  }

  .logo {
    width: 70px;
    height: 70px;
    margin-right: 18px;
  }

  .header-text {
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .special {
    font-size: 16px;
  }

  .date {
    width: 81px;
    height: 20px;
  }

  .szb-iframe {
    width: 100%;
    height: 560px;
    margin: 28px 0;
  }

  .footer {
    display: none;
  }

  .mobile-footer {
    display: block;
    width: 100%;
  }


}
